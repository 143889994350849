import { STORES_APP_IDS, STORES_NAMESPACE } from '~/external-ids';
import { SlotContext } from '~/types/common-types';

export const resolveDefaultNamespace = (
  slotContext: SlotContext | undefined,
  defaultForNonSlot: string,
): string => {
  if (slotContext?.type !== 'slot') {
    return defaultForNonSlot;
  }

  // For now we just don't want to fail for stores, so all the loose ends are
  // pointed to their namespace
  return !slotContext.appDefinitionId || STORES_APP_IDS.includes(slotContext.appDefinitionId)
    ? STORES_NAMESPACE
    : slotContext.appDefinitionId;
};
