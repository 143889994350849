// It abstracts away the complexity of the configuration setting via exports
// Each parameter is set separately, so here we try to provide batching for
// changes and possibility to to get parameters when they are set

import { Optional, Result } from '~/ts-utils';
import { initDebouncedPubSub } from '~commons/debounced-pub-sub';
import { Alignment } from '~ratings/common-types';

type ConfigSingleResource = {
  namespace: string;
  resourceId: string;
  resourceLink?: string;
  alignment?: Alignment;
};

export const createConfigurationStoreSingle = ({
  defaultNamespace,
  defaultWaitForResourceId,
  msid,
}: {
  defaultNamespace: string;
  defaultWaitForResourceId: boolean;
  msid?: string;
}) => {
  let configState:
    | { type: 'initialized'; value: ConfigSingleResource }
    | {
        type: 'not_initialized';
        value: Optional<ConfigSingleResource, 'resourceId'>;
      } = {
    type: 'not_initialized',
    value: {
      namespace: defaultNamespace,
    },
  };
  const pubSub = initDebouncedPubSub<ConfigSingleResource>();

  return {
    setNamespace: (namespace: string) => {
      if (!namespace) {
        throw new Error('Namespace cannot be empty');
      }
      configState.value.namespace = namespace;
      if (configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    setResourceId: (resourceId: string) => {
      if (!resourceId) {
        throw new Error('Resource ID cannot be empty');
      }
      configState = {
        type: 'initialized',
        value: {
          ...configState.value,
          resourceId,
        },
      };
      pubSub.publish(configState.value);
    },
    setResourceLink: (resourceLink?: string) => {
      configState.value.resourceLink = resourceLink;
      if (configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    setAlignment: (alignment: Alignment) => {
      configState.value.alignment = alignment;
      if (configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    getConfiguration: async (): Promise<Result<ConfigSingleResource>> => {
      if (configState.type === 'initialized') {
        return { type: 'ok', value: configState.value };
      }
      return defaultWaitForResourceId
        ? pubSub.waitForFirstPub()
        : { type: 'error', error: `SingleController: resourceId not passed!. MSID=${msid}` };
    },
    subscribe: pubSub.subscribe,
  };
};
