import {
  registerContainer,
  unregisterContainer,
  registerStarsWidth,
} from '~ratings/store/layout/layout-actions';

export const controllerActions = {
  registerStarsWidth,
  registerContainer,
  unregisterContainer,
};
