
  import userController from '/home/builduser/work/189599a6bfa34d79/reviews-web/wix-reviews/src/components/WixRatingsSummary/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://cd2c8e8d2a11492b9ae615ed62ef6072@sentry.wixpress.com/3606',
    id: 'ddac5f9ae34511ec9fff0242ac12001c',
    projectName: 'wix-reviews',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["todo-viewer-apps-95a5fce-e370-4402-9ce4-96956acc747d"],"centralized":true};

  var translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/189599a6bfa34d79/reviews-web/wix-reviews/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = {"enableUniversalEvents":true};

  var defaultTranslations = {"ratings-widget.full-label":"{rating} | {count, plural, =0 {reviews} one {# review} other {# reviews}}","ratings-widget.compact-label":"{rating} ({count})","ratings-widget.empty.no-reviews":"No reviews","ratings-settings.tab.display":"Display","ratings-settings.tab.design":"Design","ratings-settings.empty-state-label.title":"If a product has no reviews, show:","ratings-settings.empty-state-label.blank":"Blank empty state","ratings-settings.empty-state-label.plain-text":"Empty state with a message","ratings-settings.reset-to-original-design":"Reset to original design","ratings-settings.active-star-color.label":"Filled star opacity & color","ratings-settings.inactive-star-color.label":"Unfilled star opacity & color","ratings-settings.score-font.label":"Score text font & color","ratings-settings.score-font-size.label":"Score font size","ratings-settings.widget-alignment.label":"Widget alignment","ratings-settings.panel-title.font-and-color-picker":"Font","ratings-settings.custom-font-label.font-and-color-picker":"Custom"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/189599a6bfa34d79/reviews-web/wix-reviews/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Reviews",
    componentName: "WixRatingsSummary",
    appDefinitionId: "a95a5fce-e370-4402-9ce4-96956acc747d",
    componentId: "9c715d36-22f7-4633-b730-e73489729977",
    projectName: "wix-reviews",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "components/WixRatingsSummary/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/189599a6bfa34d79/reviews-web/wix-reviews/src/components/WixRatingsSummary/controller.ts",
  };

  export const controller = _controller
  export default controller;
