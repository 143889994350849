import type { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { RatingsSummaryBinding, SharedAppStateStore } from '~/shared-app-state/shared-app-state';
import { Alignment, RatingsSummarySetProps, RatingsSummarySlotInterface } from '../common-types';
import { addNavigation } from './add-navigation';
import { createConfigurationStoreSingle } from './configuration-store-single';
import { SlotContext } from '~/types/common-types';
import { STORES_APP_IDS, STORES_NAMESPACE } from '~/external-ids';
import { getSeoTags } from './seo-tags';
import { createInstanceManager } from '~reviews/controller/lib/get-instance';
import { REVIEWS_APP_ID } from '~/app-ids';
import { SettingsEventHandler } from '~commons/settings/create-settings-event-handler';
import {
  OPEN_TAB_EVENT,
  RatingsSummarySettingsEvent,
} from '~ratings/Settings/commons/settings-events';
import { getEnvParams } from '~commons/get-env-params';
import { listAttributeAveragesByEntity } from '@wix/ambassador-ratings-v1-rating/http';
import { RatingState } from '~commons/common-types';

export const createControllerSingle = async ({
  controllerParams,
  defaultNamespace,
  sharedAppStateStore,
  settingsEventHandler,
}: {
  controllerParams: ControllerParams;
  defaultNamespace: string;
  sharedAppStateStore?: SharedAppStateStore;
  settingsEventHandler: SettingsEventHandler<RatingsSummarySettingsEvent>;
}): Promise<IWidgetController> => {
  let sharedStoreBinding: RatingsSummaryBinding | undefined;
  const flowAPI = controllerParams.flowAPI;
  const { wixCodeApi, config, compId } = flowAPI.controllerConfig;

  const instanceManager = createInstanceManager(wixCodeApi, REVIEWS_APP_ID);
  const slotContext: SlotContext | undefined = config.publicData.COMPONENT?.slotContext;
  const configurationStore = createConfigurationStoreSingle({
    defaultNamespace,
    msid: instanceManager.getInstanceValues().metaSiteId,
    // TODO: only needed for reviews slots kill switch for stores. Remove when experiment is merged
    defaultWaitForResourceId:
      flowAPI.experiments.enabled('specs.stores.addReviewsSlotsToProductPage') ||
      slotContext?.type !== 'slot' ||
      !(slotContext?.appDefinitionId && STORES_APP_IDS.includes(slotContext?.appDefinitionId)),
  });
  const warmupResourceIdKey = `wix-ratings-summary-single-${compId}-resourceId`;
  const warmupNamespaceKey = `wix-ratings-summary-single-${compId}-namespace`;
  const warmupResourceId = wixCodeApi.window.warmupData.get(warmupResourceIdKey);
  if (warmupResourceId) {
    configurationStore.setResourceId(warmupResourceId);
  }
  const warmupNamespace = wixCodeApi.window.warmupData.get(warmupNamespaceKey);
  if (warmupNamespace) {
    configurationStore.setNamespace(warmupNamespace);
  }

  const exports = (): RatingsSummarySlotInterface => {
    return {
      set resourceId(val: string) {
        configurationStore.setResourceId(val);
        wixCodeApi.window.warmupData.set(warmupResourceIdKey, val);
      },
      set resourceLink(val: string) {
        configurationStore.setResourceLink(val);
      },
      set namespace(val: string) {
        configurationStore.setNamespace(val);
        wixCodeApi.window.warmupData.set(warmupNamespaceKey, val);
      },
      set alignment(val: Alignment) {
        configurationStore.setAlignment(val);
      },
    };
  };
  if (process.env.NODE_ENV === 'test') {
    // Yoshi testkit doesn't expose exports :(
    // @ts-expect-error
    window.exposeExports && window.exposeExports(exports());
  }
  return {
    async pageReady() {
      const { isSSR } = getEnvParams(wixCodeApi);
      const setProps = flowAPI.controllerConfig.setProps as RatingsSummarySetProps;

      if (
        flowAPI.environment.isEditor ||
        // Later we can remove this check if rating enpoint will be available in preview
        flowAPI.environment.isPreview
      ) {
        const readyRatingState = { type: 'READY' as const, overall: 4.6, totalReviews: 11 };
        configurationStore.subscribe((config) => {
          if (config.alignment) {
            setProps({
              type: 'single_state',
              ratingState: readyRatingState,
              alignment: config.alignment,
            });
          }
        });
        // The sharedAppStateStore doesn't work in editor - so just some mock here
        // Also we always prentend that we are in single state modle
        setProps({
          type: 'single_state',
          ratingState: readyRatingState,
        });
        settingsEventHandler.on(OPEN_TAB_EVENT, (payload) => {
          setProps({
            type: 'single_state',
            ratingState: payload.tabName === 'Display' ? { type: 'READY_EMPTY' } : readyRatingState,
          });
        });
        settingsEventHandler.onSettingsClose(() => {
          setProps({
            type: 'single_state',
            ratingState: readyRatingState,
          });
        });
        return;
      }
      if (!sharedAppStateStore) {
        // Something wrong with environment
        setProps({ ratingState: { type: 'IDLE' }, type: 'single_state' });
      }
      const configResult = await configurationStore.getConfiguration();

      if (configResult.type === 'error') {
        throw new Error(configResult.error);
      }
      let config = configResult.value;

      const setRatingState = (ratingState: RatingState) => {
        setProps({
          ratingState: addNavigation({
            location: wixCodeApi.location,
            ratingState,
            link: config.resourceLink,
            namespace: config.namespace,
            resourceId: config.resourceId,
          }),
          alignment: config.alignment,
          type: 'single_state',
        });
      };

      if (isSSR) {
        try {
          const response = await flowAPI.httpClient.request(
            listAttributeAveragesByEntity({
              group: config.namespace,
              entityIds: [config.resourceId],
              namespace: 'reviews',
              attributeId: 'overall',
              attributeName: 'overall',
            }),
          );
          setRatingState({
            type: 'READY',
            overall: response.data.entityAverages?.[0].average ?? 0,
            totalReviews: response.data.entityAverages?.[0].total ?? 0,
          });
        } catch (e) {
          setRatingState({ type: 'ERROR' });
        }
        return;
      }

      sharedStoreBinding = sharedAppStateStore?.registerRatingsSummary({
        resourceId: config.resourceId,
        namespace: config.namespace,
        onChange: (ratingState) => {
          if (config.namespace === STORES_NAMESPACE && ratingState.type === 'READY') {
            // ready ratingState is unavailable elsewhere, so we renderSeoTags here
            const seoTags = getSeoTags({
              resourceId: config.resourceId,
              namespace: config.namespace,
              summary: ratingState,
            });
            wixCodeApi.seo.renderSEOTags({ itemType: 'REVIEWS_COMPONENT', itemData: seoTags });
          }
          setRatingState(ratingState);
        },
      });
      configurationStore.subscribe((args) => {
        config = args;
        sharedStoreBinding?.reconfigure({
          resourceId: config.resourceId,
          namespace: config.namespace,
        });
      });
    },
    onBeforeUnLoad() {
      sharedStoreBinding?.unregister();
    },
    updateConfig(_, { publicData }) {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
    exports,
  };
};
