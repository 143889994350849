import { createReduxStore } from '~ratings/store/create-redux-store';
import { decorateActionsWithLogger } from '~commons/lib/monitoring';
import { bindActionCreators } from 'redux';
import { controllerActions } from '~ratings/controller/controller-actions';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getEnvParams } from '~commons/get-env-params';
import { State } from '~ratings/store/store-types';

export const createStore = (flowAPI: ControllerFlowAPI, listener: (state: State) => void) => {
  const { isDebug, logNetwork } = getEnvParams(flowAPI.controllerConfig.wixCodeApi);
  const store = createReduxStore();
  let oldState: State;
  store.subscribe(() => {
    const newState: State = store.getState();
    if (oldState === newState) {
      return;
    }
    oldState = newState;
    listener(newState);
  });

  const actions = decorateActionsWithLogger(
    bindActionCreators(controllerActions, store.dispatch),
    flowAPI.errorMonitor,
    isDebug || logNetwork === 'error',
  );

  return { store, actions };
};
