type RatingSummarySeoTags = {
  appName: 'Wix Ratings Summary';
  itemType: 'Product';
  itemId: string;
  itemReviewed: string;
  aggregateRating: {
    '@type': 'AggregateRating';
    ratingValue: number;
    bestRating: number;
    worstRating: number;
    reviewCount: number;
    ratingCount: number;
  };
};

export const getSeoTags = ({
  resourceId,
  namespace,
  summary,
}: {
  resourceId: string;
  namespace: string;
  summary: { overall: number; totalReviews: number };
}): RatingSummarySeoTags => ({
  appName: 'Wix Ratings Summary',
  itemType: 'Product',
  itemId: resourceId,
  itemReviewed: namespace,
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: summary.overall,
    bestRating: 5,
    worstRating: 1,
    reviewCount: summary.totalReviews,
    ratingCount: summary.totalReviews,
  },
});
