import { WixCodeApi } from '../../common/platform-types';
import { assertDefined } from '~/ts-utils';
import { parseInstance } from './parse-instance';

const getCurrentInstance = (wixCodeApi: WixCodeApi, appDefinitionId: string) => {
  const instance =
    // 'getAppToken' is the oficial way to retrieve instance, so we use it as primary source
    // https://bo.wix.com/wix-docs/fe-guild/viewer-platform/reference/site#viewer-platform_reference_site_getapptoken
    wixCodeApi.site.getAppToken?.(appDefinitionId) || wixCodeApi.user.currentUser.instance;

  assertDefined(instance);
  return instance;
};

// Everyone who takes the 'instance' has to know that it might get renewed, so we expose only this
// 'manager' so the consumer would realize it has to add 'onChange' handler also
export const createInstanceManager = (wixCodeApi: WixCodeApi, appDefinitionId: string) => {
  return {
    getInstance: () => getCurrentInstance(wixCodeApi, appDefinitionId),
    onChange: (cb: (event: { instance: string }) => void) =>
      wixCodeApi.site.onInstanceChanged(cb, appDefinitionId),
    getInstanceValues: () => parseInstance(getCurrentInstance(wixCodeApi, appDefinitionId)),
  };
};

export type InstanceManager = ReturnType<typeof createInstanceManager>;
