// It abstracts away the complexity of the configuration setting via exports
// Each parameter is set separately, so here we try to provide batching for
// changes and possibility to to get parameters when they are set

import { Result } from '~/ts-utils';
import { initDebouncedPubSub } from '~commons/debounced-pub-sub';

type ConfigMultiResource = {
  namespace: string;
  resourceList: {
    id: string;
    link?: string;
  }[];
};

export const createConfigurationStoreMulti = ({
  defaultNamespace,
  defaultWaitForResourceId,
}: {
  defaultNamespace: string;
  defaultWaitForResourceId: boolean;
}) => {
  let configState:
    | { type: 'initialized'; value: ConfigMultiResource }
    | {
        type: 'not_initialized';
        value: Omit<ConfigMultiResource, 'resourceList'>;
      } = {
    type: 'not_initialized',
    value: {
      namespace: defaultNamespace,
    },
  };
  const pubSub = initDebouncedPubSub<ConfigMultiResource>();

  return {
    setNamespace: (namespace: string) => {
      if (!namespace) {
        throw new Error('Namespace cannot be empty');
      }
      configState.value.namespace = namespace;
      if (configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    setResourceList: (resourceList?: ConfigMultiResource['resourceList']) => {
      configState = {
        type: 'initialized',
        value: {
          ...configState.value,
          resourceList: resourceList || ([] as ConfigMultiResource['resourceList']),
        },
      };
      pubSub.publish(configState.value);
    },
    getConfiguration: async (): Promise<Result<ConfigMultiResource>> => {
      if (configState.type === 'initialized') {
        return { type: 'ok', value: configState.value };
      }
      return defaultWaitForResourceId
        ? pubSub.waitForFirstPub()
        : { type: 'error', error: 'resourceId not passed!' };
    },
    subscribe: pubSub.subscribe,
  };
};
