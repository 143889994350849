import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { STORES_NAMESPACE } from '~/external-ids';
import { SharedAppStateStore } from '~/shared-app-state/shared-app-state';
import { SlotContext } from '~/types/common-types';
import { resolveDefaultNamespace } from '~commons/resolve-default-namespace';
import { createControllerMulti } from './controller/controller-multi';
import { createControllerSingle } from './controller/controller-single';
import { RATING_LIST_WIDGET_ID } from '~/app-ids';
import { createSettingsEventHandler } from '~commons/settings/create-settings-event-handler';
import { RatingsSummarySettingsEvent } from '~ratings/Settings/commons/settings-events';

const createController: CreateControllerFn = async (controllerParams) => {
  if (process.env.NODE_ENV === 'test') {
    // @ts-expect-error
    window.exposeControllerParams?.(controllerParams);
  }
  const slotContext: SlotContext | undefined =
    controllerParams.flowAPI.controllerConfig.config.publicData.COMPONENT?.slotContext;

  const sharedAppStateStore: SharedAppStateStore | undefined =
    controllerParams.appData?.sharedAppStateStore;

  const defaultNamespace = resolveDefaultNamespace(slotContext, STORES_NAMESPACE);

  const { config } = controllerParams.flowAPI.controllerConfig;
  const settingsEventHandler = createSettingsEventHandler<RatingsSummarySettingsEvent>(
    config.publicData.COMPONENT,
  );

  if (controllerParams.controllerConfig.type === RATING_LIST_WIDGET_ID) {
    return createControllerMulti({
      controllerParams,
      defaultNamespace,
      sharedAppStateStore,
      settingsEventHandler,
    });
  }

  return createControllerSingle({
    controllerParams,
    defaultNamespace,
    sharedAppStateStore,
    settingsEventHandler,
  });
};

export default createController;
